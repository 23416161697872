import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "mapSettings",
    "pill",
    "mapSettingsTab1",
    "mapSettingsTab2",
    "mapSettingsTab3",
    "fullscreenIcon",
    "minScreenIcon",
  ];

  static values = {
    superAdmin: { type: Boolean, default: null },
  };

  connect() {
    const customValue =
      this.mapSettingsTarget.dataset.comfortmapSettingsVersionValue;

    if (customValue === "movement" && !this.superAdminValue) {
      this.mapSettingsTab3Target.classList.add("map-nav-active");
    } else {
      this.mapSettingsTab1Target.classList.add("map-nav-active");
    }
    this.keydownEventListener = (event) => {
      if (event.key === "Escape") {
        this.closeSettings();
        this.smallScreen();
      }
    };
    document.addEventListener("keydown", this.keydownEventListener);

    this.mapContainer = document.getElementById("map-container");
  }

  disconnect() {
    document.removeEventListener("keydown", this.keydownEventListener);
  }

  closeSettings() {
    this.mapSettingsTarget.style.display = "none";
  }

  openSettings() {
    if (this.mapSettingsTarget.style.display === "block") {
      this.mapSettingsTarget.style.display = "none";
    } else {
      this.mapSettingsTarget.style.display = "block";
    }
  }

  fullScreen() {
    this.mapContainer.classList.add("map-container__insert__full-screen");
    this.fullscreenIconTarget.style.display = "none";
    this.minScreenIconTarget.style.display = "block";
    this.pillTarget.classList.add("map-settings__settings-pill--full-screen");
  }

  smallScreen() {
    this.mapContainer.classList.remove("map-container__insert__full-screen");
    this.fullscreenIconTarget.style.display = "block";
    this.minScreenIconTarget.style.display = "none";
    this.pillTarget.classList.remove(
      "map-settings__settings-pill--full-screen"
    );
  }

  tab1(event) {
    this.mapSettingsTab1Target.classList.add("map-nav-active");
    this.mapSettingsTab2Target.classList.remove("map-nav-active");
    this.mapSettingsTab3Target.classList.remove("map-nav-active");
    this.updateActiveTab(event);
  }

  tab2(event) {
    this.mapSettingsTab1Target.classList.remove("map-nav-active");
    this.mapSettingsTab2Target.classList.add("map-nav-active");
    this.mapSettingsTab3Target.classList.remove("map-nav-active");
    this.updateActiveTab(event);
  }

  tab3(event) {
    this.mapSettingsTab1Target.classList.remove("map-nav-active");
    this.mapSettingsTab2Target.classList.remove("map-nav-active");
    this.mapSettingsTab3Target.classList.add("map-nav-active");
    this.updateActiveTab(event);
  }

  updateActiveTab(event) {
    this.element.querySelectorAll(".map-key__nav li").forEach((li) => {
      li.classList.remove("map-key__nav--active");
    });

    event.currentTarget.classList.add("map-key__nav--active");
  }
}
