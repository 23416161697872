import { Controller } from "@hotwired/stimulus";
import { ApplicationController, useDebounce } from "stimulus-use";

export default class extends Controller {
  static debounces = ["updateUrl"];
  static targets = [
    "zipcode",
    "tripPurpose",
    "timeFrame",
    "rideFrequency",
    "gender",
    "travelWorkMode",
    "anonymity",
    "lineThickness",
    "routesSwitch",
    "hideStardEndSwitch",
    "hideHomeWorkSwitch",
    "siteId",
    "siteLoader",
  ];

  connect() {
    useDebounce(this, { wait: 1000 });
    console.log("confortmap-config connected");

    // Initialize state variables for purpose and timeframe
    this.currentPurpose = "";
    this.currentTimeframe = "";
    this.currentRideFrequency = "";
    this.currentGender = "";
    this.currentTravelWorkMode = "";
    this.currentAnonymity = "";
    this.currentRoutesSwitch = "";
    this.currentHideStardEndSwitch = "";
    this.currentHideHomeWorkSwitch = "";
  }

  updateUrlWithSiteId() {
    const siteId = this.siteLoaderTarget.value;
    const url = new URL(window.location.href);

    console.log("siteId", siteId);
    url.searchParams.set("site_id", siteId);
    window.history.pushState({}, "", url);
    window.location.reload();
  }

  updateUrl() {
    let query = encodeURIComponent(this.zipcodeTarget.value);
    if (query.length > 0) {
      let mapbox_uri = `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&proximity=ip&access_token=pk.eyJ1IjoiY29ycmVjdGVkdGltZSIsImEiOiJZQWpHMy1JIn0.wtBkQp-NkyRbRdcot5o-9g`;
      fetch(new Request(mapbox_uri))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          return response.json();
        })
        .then((json) => {
          if (json != null) {
            let [lon, lat] = json.features[0].geometry.coordinates;
            // console.log(`lon: ${lon}, lat: ${lat}`)

            // This triggers a stimulus action defined on the #map-container
            this.dispatch("setLocation", {
              detail: {
                lat: lat,
                lon: lon,
              },
            });
          }
        });
    }
  }

  updateLayer() {
    let purpose = this.currentPurpose;
    let timeframe = this.currentTimeframe;
    let rideFrequency = this.currentRideFrequency;
    let gender = this.currentGender;
    let anonymity = this.currentAnonymity;
    let routesSwitch = this.currentRoutesSwitch;
    let hideStardEndSwitch = this.currentHideStardEndSwitch;
    let hideHomeWorkSwitch = this.currentHideHomeWorkSwitch;

    console.log(
      "Updating layer with purpose and timeframe:",
      purpose,
      timeframe,
      rideFrequency
    );

    let tile_params = `?max_age=${
      this.maxAge || 60
    }&cache_bust=${new Date().getTime()}`;

    if (purpose && purpose !== "reset") {
      tile_params += `&purpose=${purpose}&line_width=10`;
    }
    if (timeframe && timeframe !== "reset") {
      tile_params += `&timeframe=${timeframe}`;
    }
    if (rideFrequency && rideFrequency !== "reset") {
      tile_params += `&frequency=${rideFrequency}`;
    }
    if (gender && gender !== "reset") {
      tile_params += `&gender=${gender}`;
    }
    if (this.currentTravelWorkMode && this.currentTravelWorkMode !== "reset") {
      tile_params += `&work_travel=${this.currentTravelWorkMode}`;
    }
    if (anonymity) {
      tile_params += `&minimum_user_threshold=${anonymity}`;
    }

    tile_params = tile_params.replace(/&privacy_filter=\d*/, "");

    if (hideStardEndSwitch === "true" && hideHomeWorkSwitch === "true") {
      tile_params += `&privacy_filter=3`;
    } else if (hideStardEndSwitch === "true") {
      tile_params += `&privacy_filter=1`;
    } else if (hideHomeWorkSwitch === "true") {
      tile_params += `&privacy_filter=2`;
    } else {
      tile_params += "";
    }

    console.log("tile_params", tile_params);

    // This triggers a stimulus action defined on the #map-container
    this.dispatch("setLocation", {
      detail: { tile_params: tile_params },
    });
  }

  updatePurpose() {
    this.currentPurpose = this.tripPurposeTarget.value
      ? encodeURIComponent(this.tripPurposeTarget.value)
      : "";

    this.updateLayer();
  }

  updateTimeframe() {
    this.currentTimeframe = this.timeFrameTarget.value
      ? encodeURIComponent(this.timeFrameTarget.value)
      : "";
    this.updateLayer();
  }

  updateRideFrequency() {
    this.currentRideFrequency = this.rideFrequencyTarget.value
      ? encodeURIComponent(this.rideFrequencyTarget.value)
      : "";
    this.updateLayer();
  }

  updateTravelWorkMode() {
    this.currentTravelWorkMode = this.travelWorkModeTarget.value
      ? encodeURIComponent(this.travelWorkModeTarget.value)
      : "";
    this.updateLayer();
  }

  updateGender() {
    this.currentGender = this.genderTarget.value
      ? encodeURIComponent(this.genderTarget.value)
      : "";
    this.updateLayer();
  }

  updateAnonymity() {
    this.currentAnonymity = this.anonymityTarget.value
      ? encodeURIComponent(this.anonymityTarget.value)
      : "";
    this.updateLayer();
  }

  updateLineThickness() {
    this.dispatch("setLocation", {
      detail: { line_thickness: this.lineThicknessTarget.value },
    });
  }

  updateUnratedRoutes() {
    this.dispatch("setLocation", {
      detail: { hide_unrated: this.routesSwitchTarget.checked },
    });
  }

  updateHideStardEnd() {
    const isChecked = this.hideStardEndSwitchTarget.checked;
    const value = isChecked ? "true" : "false";
    this.currentHideStardEndSwitch = value ? encodeURIComponent(value) : "";
    this.updateLayer();
  }

  updateHideHomeWork() {
    const isChecked = this.hideHomeWorkSwitchTarget.checked;
    const value = isChecked ? "true" : "false";
    this.currentHideHomeWorkSwitch = value ? encodeURIComponent(value) : "";
    this.updateLayer();
  }
}
