import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "firstName",
    "lastName",
    "email",
    "password",
    "signUpButton",
    "passwordHint",
    "passwordOk",
    "recapt",
    "terms",
  ];

  connect() {
    this.setupValidation();
    window.reCaptchaValidated = this.reCaptchaValidated.bind(this);
  }

  disconnect() {
    delete window.reCaptchaValidated;
  }

  setupValidation() {
    this.validationStatus = {
      firstName: false,
      lastName: false,
      email: false,
      password: false,
      reCaptcha: false,
    };

    this.emailRegex = /^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    this.nameRegex =
      /^[a-zA-ZÀ-ÿ\u0100-\u017F\u0180-\u024F\u0300-\u036F\u1E00-\u1EFF\u02BB'\u00AA \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF -]+$/;

    this.passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:;<>,.?~\-]).{8,}$/;

    this.validateForm();
  }

  validateForm() {
    const firstNameValid = this.nameRegex.test(
      this.firstNameTarget.value.trim()
    );
    this.validationStatus.firstName = firstNameValid;

    const lastNameValid = this.nameRegex.test(this.lastNameTarget.value.trim());
    this.validationStatus.lastName = lastNameValid;

    const emailValid = this.emailRegex.test(this.emailTarget.value.trim());
    this.validationStatus.email = emailValid;

    const passwordValid = this.passwordRegex.test(
      this.passwordTarget.value.trim()
    );
    this.validationStatus.password = passwordValid;

    this.updatePasswordHints();
    this.showExtra();
    this.updateButtonState();
  }

  updateButtonState() {
    const allFieldsValid = Object.values(this.validationStatus).every(
      (status) => status
    );
    this.signUpButtonTarget.disabled = !allFieldsValid;
  }

  updatePasswordHints() {
    if (this.validationStatus.password) {
      this.passwordHintTarget.style.display = "none";
      this.passwordOkTarget.style.display = "block";
    } else {
      this.passwordHintTarget.style.display = "block";
      this.passwordOkTarget.style.display = "none";
    }
  }

  showExtra() {
    if (
      this.validationStatus.firstName &&
      this.validationStatus.lastName &&
      this.validationStatus.email &&
      this.validationStatus.password
    ) {
      this.recaptTarget.style.display = "block";
      this.termsTarget.style.display = "block";
    } else {
      this.recaptTarget.style.display = "none";
      this.termsTarget.style.display = "none";
    }
  }

  reCaptchaValidated() {
    this.validationStatus.reCaptcha = true;
    this.updateButtonState();
  }
}
