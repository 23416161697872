import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["showBefore", "monthsElement", "questionsWrapper"];

  connect() {
    this.hookIntoDateElements();
  }

  includedMonthsChanged(event) {
    const validSelectiong = this.validMonthSelected();

    if (validSelectiong) {
      this.showBeforeTarget.classList.remove("hidden");
    } else {
      this.showBeforeTarget.classList.add("hidden");
    }
  }

  roundupEnabled(event) {
    console.log("roundupEnabled");
    if (event.currentTarget.value === "true") {
      this.questionsWrapperTarget.classList.remove("hidden");
    } else {
      this.questionsWrapperTarget.classList.add("hidden");
      this.monthsElementTarget.selectedIndex = 0;
    }
  }

  getDate(name) {
    const dateObject = [
      parseInt(
        document.querySelector(`select[id$=announcement_${name}_date_1i]`).value
      ),
      parseInt(
        document.querySelector(`select[id$=announcement_${name}_date_2i]`).value
      ),
      parseInt(
        document.querySelector(`select[id$=announcement_${name}_date_3i]`).value
      ),
    ];

    return new Date(dateObject);
  }

  hookIntoDateElements() {
    const elements = document
      .querySelectorAll("[data-controller$='announcements-admin-dates']")[0]
      .getElementsByTagName("select");

    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener("change", this.handleDateChange.bind(this));
    }
  }

  dateElementChanged(element) {
    document
      .getElementById(element)
      .addEventListener("change", this.handleDateChange.bind(this));
  }

  handleDateChange(event) {
    this.monthsElementTarget.innerHTML = "";

    const items = this.roundupDatesForSelect();
    this.monthsElementTarget.add(new Option(""));
    items.forEach((item) => {
      this.monthsElementTarget.add(new Option(item[0], item[1]));
    });

    this.includedMonthsChanged();
  }

  roundupDatesForSelect() {
    const startDate = this.getDate("start");
    const endDate = this.getDate("end");

    const firstMonth = startDate > new Date() ? startDate : new Date();

    let dates = [];
    let currentDate = this.advanceDateByOneMonth(firstMonth);

    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = this.advanceDateByOneMonth(currentDate);

      // Stop if we have generated 6 dates
      if (dates.length >= 6) {
        break;
      }
    }

    return dates.map((date) => [
      date.toLocaleString("default", { month: "long" }),
      date,
    ]);
  }

  advanceDateByOneMonth(date) {
    let newDate = new Date(date.getTime());
    newDate.setMonth(date.getMonth() + 1);
    return newDate;
  }

  validMonthSelected() {
    const selected = Array.from(this.monthsElementTarget.options).filter(
      function (option) {
        return option.selected;
      }
    );
    if (selected.length === 1) {
      return selected[0].value !== "";
    }

    return selected.length > 0;
  }
}
